import { TelegramWebApp } from '../types';

export const isTelegramWebAppAvailable = () => {
  return Boolean(window.Telegram?.WebApp);
};

export const initSwipeBehavior = () => {
  if (isTelegramWebAppAvailable()) {
    const tg = window.Telegram?.WebApp as TelegramWebApp | undefined;
    return {
      disableVerticalSwipe: () => {
        tg?.SettingsButton?.disableVerticalSwipe?.();
      },
      enableVerticalSwipe: () => {
        tg?.SettingsButton?.enableVerticalSwipe?.();
      },
      isVerticalSwipeEnabled: false
    };
  }
  // Возвращаем заглушку, если не в среде Telegram
  return {
    disableVerticalSwipe: () => {},
    enableVerticalSwipe: () => {},
    isVerticalSwipeEnabled: false
  };
};
